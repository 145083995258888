import moment from 'moment';
import { useState, useEffect } from 'react'
import prisma from '../prisma/db';
import { formatPrice, get_tax_amount, per_person_from_villa_price, price_with_tax } from './utillities/prices';

export function pluralize(value, word, suffix = 's') {
  if (value == 1) {
    return value + ' ' + word;
  }
  else {
    let newWord = word;
    if (suffix !== 's')
      newWord = newWord.slice(0, -1);
    return value + ' ' + newWord + suffix;
  }
}

export const default_child_discount = [{ name: 'Adults', age: '10+', disc: 100 }, { name: 'Kids', age: '5-10', disc: 50 }, { name: 'Infants', age: '0-5', disc: 0 }];
export function calculatePerPersonPrice(price, kids, guests, startDate, endDate, type) {
  let nights = 1;
  if (type !== 1)
    nights = dateDiffInDays(startDate.toDate(), endDate.toDate());

  let total_guests = 0;
  if (kids && kids.length > 0) {
    kids.forEach((kid, k) => {
      if (guests[k]) {
        total_guests += guests[k] * kid.disc / 100;
      }
    })
  }
  let perPersonPrice = (price / (total_guests)) / nights;
  return Math.floor(perPersonPrice);
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function useSearchDebounce(delay = 350) {
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}

export function isJSON(str) {
  try {
    return (JSON.parse(str) && !!str);
  } catch (e) {
    return false;
  }
}

function limitNum(num, max) {
  return Math.ceil(Math.min(num, max));
}

export const capitalizeString = string => string.split(' ').map(item => item.replace(item.charAt(0), item.charAt(0).toUpperCase())).join(' ');

export const getDaysArray = function (startDt, endDt) {
  let start = isValidDate(startDt) ? startDt : new Date(startDt);
  let end = isValidDate(endDt) ? endDt : new Date(endDt);
  for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export function enumerateDaysBetweenDates(startDate, endDate) {
  let date = []
  let startDateM = moment(moment(startDate).format("YYYY-MM-DD"));
  let endDateM = moment(moment(endDate).format("YYYY-MM-DD"));
  while (startDateM <= endDateM) {
    date.push(startDateM.day());
    startDate = startDateM.add(1, 'days');
  }
  return date;
}

export function dateDiffInDays(a, b) {
  // a and b are javascript Date objects

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function findPriceRule(price_rules, date) {
  let rule_id = -1;
  price_rules.forEach((rule, k) => {
    if (!rule.end_date) rule.end_date = rule.start_date
    if (rule.type === 1) {
      if (moment(date).isBetween(moment(rule.start_date), moment(rule.end_date), 'day', '[]')) {
        rule_id = k;
      }
    }
    else {
      if (rule_id !== -1 && price_rules[rule_id].type === 1) return;
      const days = enumerateDaysBetweenDates(rule.start_date, rule.end_date);
      if (moment(date).format("YYYY-MM-DD") >= moment(rule.start_date).format("YYYY-MM-DD") && days.includes(moment(date).day())) {
        rule_id = k;
      }
    }
  })
  return rule_id;
}

export function getPricing(option, dates, pricing_model = 1, type, steal_deal = null) {
  const rules_on_dates = []
  let prices = [];
  let unit_price = 0;
  let b2b_prices = [];

  dates.forEach(date => {
    const rule_id = findPriceRule(option?.price_rules || [], date)
    let rule_prices = rule_id !== -1 ? option?.price_rules[rule_id]?.prices.split(',') : option?.prices.split(',');
    let unit_rule_price = 0;
    let b2b_rule_prices = [];

    if (rule_id !== -1) {
      if ((!type ? true : type === 3)) {
        if (option?.price_rules[rule_id]?.price_type === 2) {
          rule_prices = per_person_from_villa_price(parseInt(option?.price_rules[rule_id]?.prices), option?.minimum, option?.maximum)
          unit_rule_price = parseInt(option?.price_rules[rule_id]?.prices)
        }
        else {
          unit_rule_price = parseInt(rule_prices[0]) * parseInt(option?.minimum)
        }
      }
      else {
        unit_rule_price = parseInt(rule_prices[0]) * parseInt(option?.minimum)
      }
      if (pricing_model === 2) {
        b2b_rule_prices = rule_prices.map(price => Math.round(price - ((parseInt(price) * parseInt(option?.price_rules[rule_id]?.b2b_prices)) / 100)));
      }
      else {
        if ((!type ? true : type === 3) && option?.price_rules[rule_id]?.b2b_price_type === 2)
          b2b_rule_prices = per_person_from_villa_price(parseInt(option?.price_rules[rule_id]?.b2b_prices), option?.minimum, option?.maximum)
        else
          b2b_rule_prices = option?.price_rules[rule_id]?.b2b_prices?.split(',');
      }
    }
    else {
      if ((!type ? true : type === 3)) {
        if (option?.price_type === 2) {
          rule_prices = per_person_from_villa_price(parseInt(option?.prices), option?.minimum, option?.maximum)
          unit_rule_price = parseInt(option?.prices)
        }
        else {
          unit_rule_price = parseInt(rule_prices[0]) * parseInt(option?.minimum)
        }
      }
      else {
        unit_rule_price = parseInt(rule_prices[0]) * parseInt(option?.minimum)
      }
      if (pricing_model === 2) {
        b2b_rule_prices = rule_prices.map(price => Math.round(parseInt(price) - ((parseInt(price) * parseInt(option?.b2b_prices)) / 100)));
      }
      else {
        if ((!type ? true : type === 3) && option?.b2b_price_type === 2)
          b2b_rule_prices = per_person_from_villa_price(parseInt(option?.b2b_prices), option?.minimum, option?.maximum)
        else
          b2b_rule_prices = option?.b2b_prices?.split(',');
      }
    }

    rule_prices = rule_prices.map((price) => {
      if (rule_id !== -1)
        return parseInt(price) + ((parseInt(price) * (option?.price_rules[rule_id]?.price_booster ?? 0)) / 100)
      else
        return parseInt(price) + ((parseInt(price) * (option?.price_booster ?? 0)) / 100)
    })

    if (unit_rule_price > 0) {
      if (rule_id !== -1)
        unit_rule_price = parseInt(unit_rule_price) + ((parseInt(unit_rule_price) * (option?.price_rules[rule_id]?.price_booster ?? 0)) / 100)
      else
        unit_rule_price = parseInt(unit_rule_price) + ((parseInt(unit_rule_price) * (option?.price_booster ?? 0)) / 100)
    }

    rule_id !== -1 && rules_on_dates.push({ date, rule_id: option?.price_rules[rule_id]?.id })

    if (prices.length)
      prices.forEach((price, index) => {
        prices[index] = Math.ceil((parseInt(price) + parseInt(rule_prices[index])) / 2)
      })
    else
      prices = rule_prices

    if (unit_price > 0)
      unit_price = Math.ceil((unit_rule_price + unit_price) / 2)
    else
      unit_price = unit_rule_price

    if (b2b_prices?.length)
      b2b_prices.forEach((price, index) => {
        b2b_prices[index] = Math.ceil((parseInt(price) + parseInt(b2b_rule_prices[index])) / 2)
      })
    else
      b2b_prices = b2b_rule_prices
  })

  if ((!type ? true : type !== 3))
    prices = prices.map((price, k) => formatPrice(price, steal_deal))

  unit_price = formatPrice(unit_price, steal_deal)

  return { prices, b2b_prices, rules_on_dates, unit_price }
}

export async function calculatePrice(checkin, checkout, uname, camps, addons, coupon, credit, user, paymentOp) {
  let price = 0;
  let b2b_price = 0;
  let price_paid = 0;
  let regular_price = 0;
  let b2b_tax = 0;
  let commission_tax = 0;

  let nights = dateDiffInDays(checkin, checkout);
  const pack = await prisma.renamedpackage.findUnique({
    where: {
      uname: uname,
    },
    select: {
      id: true,
      uname: true,
      type: true,
      meta: {
        select: {
          pricing_model: true,
          pay_online: true,
        }
      },
      options: {
        select: {
          id: true,
          uuid: true,
          minimum: true,
          maximum: true,
          prices: true,
          b2b_prices: true,
          child_discount: true,
          price_type: true,
          b2b_price_type: true,
          price_booster: true,
          price_rules: {
            where: {
              OR: [
                {
                  type: 2,
                },
                {
                  start_date: {
                    gte: new Date(checkin),
                    lt: new Date(checkout),
                  },
                }
              ]
            },
            select: {
              id: true,
              type: true,
              start_date: true,
              end_date: true,
              prices: true,
              b2b_prices: true,
              price_booster: true,
              price_type: true,
              b2b_price_type: true,
            }
          }
        }
      },
      sale_lists: {
        where: {
          start_date: {
            lte: new Date()
          },
          end_date: {
            gte: new Date()
          }
        },
        orderBy: {
          start_date: 'asc'
        },
        take: 1,
        select: {
          discount: true,
        }
      }
    }
  });

  const steal_deal = pack?.sale_lists[0]
  const pay_online = pack.meta?.pay_online || 100;
  const pricing_model = pack.meta?.pricing_model || 1;

  if (pack.type === 1)
    nights = 1;

  let guests = 0;

  const bookedPackageOptions = camps.map((camp) => {
    let option = pack.options.find(e => e.uuid === camp.type);
    if (!option) {
      throw new Error(`Camp type not found...`)
    }

    const { prices, b2b_prices, rules_on_dates } = getPricing(option, getDaysArray(new Date(checkin), new Date(checkout).setDate(new Date(checkout).getDate() - 1)), pricing_model, pack.type, steal_deal)

    const child_discount = option.child_discount ? JSON.parse(option.child_discount) : default_child_discount;

    let room_price = 0;
    let room_b2b_price = 0;
    let room_regular_price = 0;
    let total_guests = 0;

    camp.kids.map((rule, k) => {
      room_price += nights * parseInt(prices[camp.kids[0].guests - option.minimum]) * rule.guests * child_discount[k].disc / 100; // index 0 always has number of adults and child price depands on number of adults in each camp
      room_b2b_price += nights * parseInt(b2b_prices[camp.kids[0].guests - option.minimum]) * rule.guests * child_discount[k].disc / 100;
      room_regular_price += nights * formatPrice(parseInt(option.prices.split(',')[camp.kids[0].guests - option.minimum]) + ((parseInt(option.prices.split(',')[camp.kids[0].guests - option.minimum]) * option?.price_booster) / 100)) * rule.guests * child_discount[k].disc / 100;
      price_paid += nights * (parseInt(prices[camp.kids[0].guests - option.minimum]) * (pay_online > 100 ? 100 : pay_online) / 100) * rule.guests * child_discount[k].disc / 100;
      total_guests += rule.guests * child_discount[k].disc / 100;
    });

    guests += camp.kids[0].guests;
    room_b2b_price = Math.ceil(room_b2b_price);

    // room_price = price_with_tax(room_price, room_b2b_price, nights, total_guests);
    // room_regular_price = price_with_tax(room_regular_price, room_b2b_price, nights, total_guests);
    const { b2b_tax: room_b2b_tax, commission_tax: room_commission_tax } = get_tax_amount(room_price, room_b2b_price, nights);

    price += room_price;
    b2b_price += room_b2b_price;
    regular_price += room_regular_price;
    b2b_tax += room_b2b_tax;
    commission_tax += room_commission_tax;

    return {
      package_option: { connect: { id: option.id } },
      guests: total_guests,
      price: room_price,
      b2b_price: room_b2b_price,
      b2b_tax: room_b2b_tax,
      tax: room_commission_tax,
      kids: JSON.stringify(camp.kids),
    }
  })

  price = Math.ceil(price)
  b2b_price = Math.ceil(b2b_price)
  price_paid = Math.ceil(price_paid)
  regular_price = Math.ceil(regular_price)

  let couponMsg = { success: true, code: coupon }
  let couponId = null;
  let discount = 0;

  if (coupon) {
    let stay_dates = getDaysArray(new Date(checkin), new Date(checkout).setDate(new Date(checkout).getDate() - 1));
    stay_dates = stay_dates.map((date) => {
      return moment(date).format('YYYY-MM-DD');
    });
    if (stay_dates.includes('2023-12-31')) {
      couponMsg = { success: false, msg: 'Coupon Codes are not applicable for 31st Dec!' }
    }
    else if(steal_deal?.discount) {
      couponMsg = { success: false, msg: 'Coupon Codes are not applicable during Steal Deal' }
    }
    else {
      const couponData = await prisma.coupon.findUnique({
        where: {
          code: coupon,
        },
        select: {
          id: true,
          code: true,
          type: true,
          per_person: true,
          min_person: true,
          max_person: true,
          max_uses: true,
          max_uses_user: true,
          starts_at: true,
          expires_at: true,
          discount: true,
          max_discount: true,
          discount_type: true,
          user: {
            select: {
              id: true,
            }
          }
        }
      });

      if (couponData) {
        if (pack.type === 3 && couponData.min_person >= 5) {
          couponMsg = { success: false, msg: 'Coupon Codes for 5+ people not applicable for Villas!' }
        }
        else if ((couponData.min_person && guests < couponData.min_person) || (couponData.max_person && guests > couponData.max_person) || (couponData.user && couponData.user.id !== user.id)) {
          couponMsg = { success: false, msg: `Coupon is valid only when you book for ${couponData.min_person} ${couponData.max_person ? (couponData.max_person === couponData.min_person ? '' : `to ${couponData.max_person}`) : 'or more'} Adults` }
        }
        else {
          if (couponData.type === 1) {
            if (couponData.per_person === 1) {
              discount = couponData.discount * guests * nights
            }
            else {
              discount = couponData.discount * nights
            }
          }
          else {
            discount = price * couponData.discount / 100;
          }

          if (couponData.max_discount && discount > couponData.max_discount) {
            discount = couponData.max_discount;
          }

          price = price - discount;
        }
      }
      else {
        couponMsg = { success: false, msg: 'Invalid Coupon Code!' }
      }

      if (discount > 0)
        couponId = couponData.id;
    }
  }

  let creditId = null;
  let creditsApplied = 0;

  if (credit !== null && credit !== undefined && credit >= 0) {
    const credits = await prisma.hac_credits.findFirst({
      where: {
        id: credit,
        user_id: user.id,
        starts_at: {
          lte: new Date()
        },
        OR: [
          {
            expires_at: null,
          },
          {
            expires_at: {
              gte: new Date()
            },
          },
        ]
      },
      orderBy: {
        remaining: 'desc'
      },
      select: {
        id: true,
        remaining: true,
        capping: true,
      }
    })


    if (credits?.remaining > 0) {
      let applicable = credits.capping === 0 ? limitNum((price - b2b_price), credits.remaining) : limitNum((price - b2b_price) * credits.capping / 100, credits.remaining)
      if (applicable > price || applicable > (price - b2b_price)) {
        applicable = 0;
      }
      else if (applicable > credits.remaining) {
        applicable = credits.remaining;
        price = price - applicable;
      }
      else {
        price = price - applicable;
      }
      creditsApplied = applicable
      creditId = credits.id
    }
  }

  if (price_paid > price)
    price_paid = price;

  couponMsg.discount = discount;

  let addons_price = 0;
  let addons_price_paid = 0;
  let addons_b2b = 0;
  let booked_add_ons = [];

  if (addons.length) {
    await Promise.all(addons.map(async (addon) => {
      if (addon.quantity > 0) {
        const addonData = await prisma.add_on.findFirst({
          where: {
            id: addon.id,
            package: {
              id: pack.id
            }
          },
          select: {
            id: true,
            price: true,
            pay_online: true,
            b2b_price: true,
          }
        })
        const addon_price = formatPrice(addonData.price) * addon.quantity;
        const addon_paid = (addonData.price * (addonData.pay_online > 100 ? 100 : addonData.pay_online) / 100) * addon.quantity;
        const addon_b2b = addonData.b2b_price * addon.quantity;
        addons_price += addon_price;
        addons_price_paid += addon_paid;
        addons_b2b += addon_b2b;
        booked_add_ons.push({ addon_id: addon.id, totalamount: addon_price, paid: addon_paid, b2b: addon_b2b, quantity: addon.quantity })
      }
    }))
  }

  if (price_paid === 0) {
    price_paid = price;
  }

  price += addons_price;
  price_paid += addons_price_paid;
  b2b_price += addons_b2b;

  if (paymentOp === 2) {
    addons_price_paid = addons_price
    price_paid = price;
  }

  if (price === 0) {
    price = 1;
    price_paid = 1;
  }

  //2% Platform Fee
  let platform_fee = Math.ceil((price + creditsApplied + discount) * 0.025)

  return { platform_fee, pack, price, regular_price, price_paid, bookedPackageOptions, couponMsg, couponId, credits: price - b2b_price, creditsApplied, creditId, addons_price, addons_price_paid, booked_add_ons, b2b_price, nights, b2b_tax, commission_tax, tax: b2b_tax + commission_tax };
}

export function compareRegulerWithRule(a, b) {
  a.prices.forEach((price, k) => {
    if (b.prices[k] < price) {
      a[k] = b.prices[k];
    }
  })
}

export function round(num, decimalPlaces) {
  num = Math.round(num + "e" + decimalPlaces);
  return Number(num + "e" + -decimalPlaces);
}

export function dateWithoutTimezone(date) {
  let d = new Date(date);
  d.setHours(0, 0, 0, 0)
  d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000 /* convert to UTC */ + (/* UTC+8 */ 12) * 60 * 60 * 1000);
  return new Date(d);
}
